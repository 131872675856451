<template>
  <div class="repairedHandle" ref="repairedHandle">
    <van-pull-refresh
      v-model="loading"
      class="workWait"
      id="workWait"
      @refresh="searchEvent"
    >
      <div class="list" v-if="list.length > 0">
        <overflowYHidden>
          <subitemV4
              @clickItem="toRepairedOrder(item)"
            :rowKeyList="rowKeyList"
            v-for="item in list"
            :key="item.key"
            :dataInfo="item"
          >
            <template slot="footer">
              <div class="headleBtn" @click.stop="toRepairInfo(item)">维修信息</div>
              <div class="headleBtn" @click.stop="toHandleDetails(item)">处理</div>
            </template>
          </subitemV4>
          <div class="loadMore" v-show="loadMore">
            <i class="el-icon-loading"></i>更多加载中...
          </div>
          <div
            class="loadMore"
            v-show="totalCount != 0 && totalCount == list.length"
          >
            没有更多数据了
          </div>
        </overflowYHidden>
      </div>
      <div v-else class="emptyView">
        <KcEmpty description="暂无数据"></KcEmpty>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import subitemV4 from "./subitemV4";
import overflowYHidden from "@/components/overflowYHidden";
import repairMixin from '@views/shareAndDispatch/device/deviceRepair/reapirList/repairMixin'

export default {
  name: "repairedHandle",
  components: { subitemV4, overflowYHidden },
  mixins:[repairMixin],
  data() {
    return {
      page: 1,
      pageSize: 30,
      totalCount: 0,
      loadMore: false,
      loading: false,
      rowKeyList: [
        { key: "deviceCode", name: "设备编号", space: "21px", float: "right" },
        {
          key: "categoryName",
          name: "设备类型",
          space: "21px",
          float: "right",
        },
        { key: "reportUser", name: "报修人", space: "21px", float: "right" },
        { key: "reportTime", name: "报障时间", space: "21px", float: "right" },
      ],
      list: [],
      scrollbarEl: "",
    };
  },
  created() {},
  mounted() {
    this.reportList();
    this.scrollbarEl = this.$refs.repairedHandle;
    this.scrollbarEl.onscroll = () => {
      this.scrollEvent();
    };
  },
  methods: {
    scrollEvent() {
      let scrollTop = this.scrollbarEl.scrollTop;
      let offsetHeight = this.scrollbarEl.offsetHeight;
      let scrollHeight = this.scrollbarEl.scrollHeight;
      if (
        scrollTop + offsetHeight == scrollHeight &&
        this.totalCount > this.list.length
      ) {
        this.page += 1;
        this.loadMore = true;
        this.reportList();
      }
    },
    searchEvent() {
      this.page = 1;
      this.loadMore = true;
      this.reportList();
    },
    reportList() {
      this.$showLoading({
        target: ".repairedHandle",
      });
      this.$api.deviceRepair
        .reportList({
          queryType: 4,
          page: this.page,
          pageSize: this.pageSize,
        })
        .then((res) => {
          this.loadMore = false;
          this.loading = false;
          this.totalCount = res.totalCount;
          if (this.page == 1) {
            this.list = res.data;
          } else {
            this.list.push(...res.data);
          }
          this.$hideLoading({
            target: ".repairedHandle",
          });
        })
        .catch((res) => {
          this.loading = false;
          this.$hideLoading({
            target: ".repairedHandle",
          });
        });
    },
    toRepairedOrder(item) {
      this.$push("deviceRepair/repairedOrder", {
        id: item.id,
        serialNumber: item.serialNumber,
        categoryName: item.categoryName,
        reportTime: item.reportTime,
      });
    },
    toRepairInfo(item) {
      this.$push("deviceRepair/repairInfo", {
        id: item.id,
      });
    },
    toHandleDetails(item) {
      this.$push("deviceRepair/handleDetails", {
        id: item.id,
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.repairedHandle {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 10px;
  .list {
    height: 100%;
  }
  .emptyView {
    height: 100%;
  }
  .loadMore {
    text-align: center;
    color: $color4;
    font-size: 12px;
    padding: 12px;
  }
}
</style>